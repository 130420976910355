@import './variable';
@import './typography';

.calendar-container {
    &.full {
        position: absolute;
        top: 0px !important;
        z-index: 99999;
        height: 100%;
    }
    flex: 1;
    position: relative;
    left: 0;
    bottom: 0;
    margin-left: -12px;
    //height: calc(100vh - 474px);
    // height: 100%;
    min-height: 354.59px;
    width: calc(100% + 24px);
    background: $bg-300;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 1.875rem 1.875rem 0 0;

    > .event-list {
        flex: 1;
        width: 100%;
        overflow: scroll;
    }

    .calendar-inner-container {
        &.full {
            height: 100%;
            margin-top: 1rem;
        }

        height: 100%;
        width: 100%;
        overflow: hidden;

        .calendar-inner-overflow {
            overflow: auto;
            height: 100%;

            &::-webkit-scrollbar {
                display: none;
            }

            .calendar-inner {
                &.full {
                    height: 100px;
                }

                &:nth-child(1) {
                    border-radius: 1.875rem 1.875rem 0 0;
                }

                width: 100%;
                background: #293560;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 1.85rem 0 1.85rem 0;

                .hour-text {
                    font: normal $weight-400 1rem/1.625rem $font-2;
                    color: $white;
                    margin-left: 1rem;
                }

                .hour-divider {
                    height: 1px;
                    width: 80%;
                    background: $white;
                    opacity: 20%;
                }
            }
        }
    }

    .calendar-notch {
        height: 0.375rem;
        width: 3.75rem;
        background: $white;
        opacity: 16%;
        border-radius: 0.188rem;
        margin-top: 0.625rem;
    }

    .cal-text-div {
        align-self: stretch;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 1.5rem 1rem 1.5rem 0rem;

        .cal-inner-text {
            font: normal $weight-600 1.125rem/1.35rem $font-1;
            color: $white;
            margin-left: 1rem;
        }
    }

    .rbc-time-view {
        background: $bg-400;
        border: none;
        border-radius: 30px 30px 0px 0px;
    }

    .rbc-today {
        background: transparent;
    }

    .rbc-time-content,
    .rbc-time-header-content,
    .rbc-overflowing {
        border: none !important;

        > * + * > * {
            border-left: none;
        }
    }

    .rbc-time-gutter {
        .rbc-timeslot-group {
            border: none !important;

            .rbc-label {
                font: normal $weight-400 1rem/1.625rem $font-2;
                letter-spacing: 0.002em;
                color: $white;
                margin-left: 16px;
            }
        }
    }

    .rbc-timeslot-group {
        min-height: 85px;
        border-bottom: none;
    }

    .rbc-now {
        margin-top: 15px;
        margin-left: 20px;

        .rbc-time-slot {
            border-top: 1px solid rgba(255, 255, 255, 0.2);
        }

        .rbc-events-container {
            margin-right: 0px !important;
        }
    }

    .rbc-current-time-indicator {
        background-color: $golden;
        height: 1.5px;
        margin-left: -90px;
    }

    .rbc-current-time-indicator-shape {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        background-color: $golden;
        margin-top: -5.5px;
        margin-left: -12px;
    }

    .rbc-event {
        max-width: 95%;
        margin-left: 4.5%;
        padding: 5px 21px;
        background-color: $light-turquoise;
        border-color: $light-turquoise;
        border-left: 4px solid $turquoise !important;
        justify-content: center;

        .rbc-event-label,
        .rbc-event-content {
            //implements gradient text color
            background-color: $black; //fallback
            background-image: $stroke;
            background-size: 100%;
            background-repeat: repeat;
            background-clip: 'text';
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            -moz-background-clip: text;
            -moz-text-fill-color: transparent;
        }

        .rbc-event-label {
            order: 2;
            font: normal $weight-400 1rem/1.625rem $font-2;
            letter-spacing: 0.002em;
        }

        .rbc-event-content {
            margin-bottom: 6px;
            order: 1;
            flex: 0 0 max-content;
            height: max-content;
            max-height: 70%;
            font: normal $weight-600 1.125rem/1.375rem $font-1;
            letter-spacing: 0.005em;
        }
    }

    .short-event {
        display: grid !important;
        grid-template-columns: 1fr max-content;
        gap: 10px;
        align-content: center;

        .rbc-event-label {
            margin-top: 0px;
            margin-bottom: 0px;
        }

        .rbc-event-content {
            height: 100%;
            min-height: 26px;
            margin-top: 0px;
            margin-bottom: 0px;
            line-height: 1.625rem;
        }
    }

    // .current-event {
    //   background-color: $green;
    //   border-color: $green;
    // }

    // .past-event {
    //   background-color: $dark-grey;
    //   border-color: $dark-grey;
    // }
}

.empty-calendar {
    height: 84px;
    box-sizing: border-box;
    display: flex;
    background-color: $bg-400;
    align-items: center;

    > p {
        margin: 0;
        padding: 0;
        font-size: 1.125rem;
        font-family: $font-1;
        color: $light-grey;
        font-weight: $weight-600;
    }
}

.custom-calendar-event {
    display: flex;
    gap: 1rem;
    margin: 0 0.5rem 1rem 1rem;
    border-radius: 8px 16px 16px 8px;
    overflow: hidden;
    background-color: $bg-400;

    > .warning {
        background-color: $warning;
        width: 5px;
    }
    > .light-grey {
        background-color: $light-grey;
        width: 5px;
    }
    > .white {
        background-color: $white;
        width: 5px;
    }
    > .turquiose {
        width: 5px;
        background-color: $turquoise;
    }
}

.event-info {
    flex: 1;
    padding: 1rem 0;
    padding-right: 1rem;

    > div {
        display: flex;
        align-items: center;

        /* > button {
            padding: 12px 30px;
            font-family: $font-1;
            border-radius: 2rem;
            color: $turquoise;
            outline: none;
            border: 2px solid $turquoise;
            background-color: transparent;
            font-size: 1rem;
            font-weight: $weight-600;
        } */
    }
}

.event-progression {
    height: 5px;
    background-color: $bg-300;
    margin-top: 1rem;

    > div {
        height: 100%;
        background-color: $turquoise;
    }
}

.event-title {
    flex: 1;
    color: $white;
    > h3 {
        font-family: $font-1;
        font-weight: 500;
        letter-spacing: 0.005em;
        font-size: 1.2rem;
        margin: 0;
        padding: 0;
    }

    > span {
        font-family: $font-2;
        // font-weight: 400;
        letter-spacing: 0.002em;
        font-size: 1rem;
    }
}

.button-enabled {
    padding: 12px 30px;
    font-family: $font-1;
    border-radius: 2rem;
    color: $turquoise;
    outline: none;
    border: 2px solid $turquoise;
    background-color: transparent;
    font-size: 1rem;
    font-weight: $weight-600;
}

.button-disabled {
    padding: 12px 30px;
    font-family: $font-1;
    border-radius: 2rem;
    color: $light-grey;
    outline: none;
    border: 2px solid $light-grey;
    background-color: transparent;
    font-size: 1rem;
    font-weight: $weight-600;
    cursor: not-allowed;
}
