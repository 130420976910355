@import './variable';
@import './typography';

.portal-list-container {
    .no-portals {
        color: $white;
    }

    .title {
        color: $white;
        margin-bottom: 1.25rem;
    }

    .search-container {
        input::placeholder {
            color: $light-grey;
        }

        .search-icon {
            position: absolute;
            right: 1.7rem;
            top: 0.9rem;
            font-size: 1.5rem;
            z-index: 9999;
        }
    }

    .portal-list {
        overflow: hidden;
        position: absolute;
        z-index: 1;
        width: 100%;

        ul {
            padding: 0 0.5rem 8.125rem 0.5rem;
            margin-top: 1rem;
            margin-bottom: 0;
            list-style: none;
            color: $white !important;
            overflow: scroll;

            li {
                &:hover,
                &.selected {
                    background-color: $bg-300;
                    color: $white;

                    i::before {
                        color: $white;
                    }
                }

                border-radius: 0.5rem;
                padding: 0.813rem 0.75rem 0.813rem 0rem;
                width: 100%;
                display: inline-flex;
                margin-bottom: 1.25rem;

                &.disabled {
                    color: $dark-grey !important;
                    pointer-events: none;

                    i::before {
                        color: $light-grey;
                    }

                    background-color: transparent !important;
                    .portals-icon {
                        opacity: 0.3;
                        pointer-events: none;
                    }
                    .booked-color,
                    .in-call-color,
                    .invited-color,
                    .in-maintenance-color,
                    .offline-color,
                    .disconnected-color {
                        opacity: 0.6;
                    }
                }

                .custom-checkbox {
                    margin-left: 0.875rem;
                    margin-right: 0.063rem;

                    .icon-checked {
                        &::before {
                            color: $turquoise;
                        }
                    }
                }

                .checkbox-disabled {
                    opacity: 0.3;
                }

                .avatar-img {
                    margin-right: 0.75rem;
                    margin-left: 1rem;
                }
                .portals-icon {
                    height: 3rem;
                    width: 3rem;
                    display: flex;
                    align-self: center;
                    justify-content: center;
                    margin-right: 0.75rem;
                    margin-left: 1rem;
                    // border: 2px solid $white;
                    border-radius: 2rem;
                    padding: 0.6rem;
                    background-color: $toast-bg1;
                }
            }
        }
    }

    .checkbox-input-container {
        gap: 0.604rem;
        align-items: center;

        .MuiCheckbox-root {
            position: relative;
            margin-top: 0;
            margin-bottom: 0;
            padding: 0;
            outline: none;
            box-shadow: none;
            width: 1.25rem;
            height: 1.25rem;
            border: 2px solid $light-grey;
            border-radius: 5px;
            color: transparent;
            transition: all 0.15s;
        }

        .Mui-checked {
            border-color: $turquoise;
            color: $turquoise;
            background-color: $white;
        }

        .Mui-disabled {
            border-color: $light-grey;
            color: $light-grey;
            background-color: $light-grey;
        }

        .custom-label {
            font: normal $weight-400 1rem/1.625rem $font-2;
            letter-spacing: 0.002em;
            color: $white;
            padding: 0;
            margin: 0;
        }
    }
}
