@import './variable';
@import './typography';

.form-label {
    color: $white;
}
select.form-select {
    background: $stroke;
    border: 2px solid $bg-500;
    color: $white;
    option {
        background: $bg-200;
        color: $white;
    }
    &.is-invalid {
        background-repeat: no-repeat;
    }
}
.form-control {
    background: $stroke;
    border: 2px solid $bg-500;
    color: $white;
    /* width: 21.438rem; */
    height: 4rem;
    border-radius: 2rem;
    padding-left: 1.4rem;
    font: normal $weight-400 1rem/1.625rem $font-2 !important;
    letter-spacing: 0.002em !important;
    &:hover,
    &:focus {
        border: 2px solid $white;
        color: $white;
        box-shadow: none;
    }
    &.is-invalid {
        border: 2px solid $coral;
        background-size: calc(0.35em + 2.6rem) calc(1.25em + 0.275rem);
        color: $white;
        background-image: url('../assets/imgs/error.svg');
        &:hover,
        &:focus {
            border: 2px solid $coral;
            background: $stroke;
            box-shadow: none;
        }
    }
    &.is-valid {
        border: 2px solid $green;
        background-size: calc(0.35em + 2.6rem) calc(1.25em + 0.275rem);
        color: $white;
        background-image: url('../assets/imgs/checked.svg');
        &:hover,
        &:focus {
            border: 2px solid $green;
            background: $stroke;
            box-shadow: none;
        }
    }
    &.custom-search-input {
        border: 2px solid $bg-500;
        background: $stroke;
        border-top-right-radius: 2rem !important;
        border-bottom-right-radius: 2rem !important;
        &:hover,
        &:focus {
            border: 2px solid $white;
            color: $white;
            box-shadow: none;
        }
        &:disabled,
        &:hover,
        &:focus {
            background: transparent !important;
        }
    }
    &:disabled {
        background: $light-grey;
        border: 2px solid $light-grey;
        color: $white;
        &:hover,
        &:focus {
            background: $light-grey;
            border: 2px solid $light-grey;
            color: $white;
            box-shadow: none;
        }
    }
    &::placeholder {
        color: $white;
    }
}
