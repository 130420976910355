$turquoise: #4ad0ee;
$light-turquoise: #b7ecf8;
$golden: #fec400;
$green: #4caf50;
$bg-100: linear-gradient(167.96deg, #05103a 0%, #0c1433 49.79%, #000000 100%);
$bg-200: #0d1a46;
$bg-300: #14224f;
$bg-400: #293560;
$bg-500: #245e7d;
$stroke: linear-gradient(133.98deg, #070e29 16.55%, #0b1334 86.38%);
$coral: #f44336;
$green: #4caf50;
$dark-grey: #5f6985;
$light-grey: #878ea0;
$white: #ffffff;
$black: #000000;
$toast-bg1: #455282;
$avatar-bg: #7481ae;
$bg-200-rgba06: rgba(13, 26, 70, 0.6);
$danger: #f43f5e;
$success: $green;
$warning: #ffc107;
$toast-error: linear-gradient(0deg, #293560, #293560),
    linear-gradient(90deg, rgba(244, 67, 54, 0.2) 0%, rgba(244, 67, 54, 0) 100%);

$disabled-opacity: 0.5;
